<template>
	<div class="">
		<!-- Banner -->
		<div style="position: relative; " class="index">
			<particles class="background"></particles>
		</div>
		<!-- banner -->
		<!-- 分类 PC -->
		<div class="content pc">
			<!-- 导航 -->
			<div :key="componentKey">
				<detail :tdata="tData" :id="trendId" v-if="trendId" :isFixedNav="isFixedNav" :actindex="actindex" ref="detail"></detail>
			</div>
		</div>
				
		<!-- 主题赛事 Mobile -->		
		<div class="mobiles trends containter">
			<div class="title">主题赛事<!-- <span>更多</span> --></div>
			<div class="list" v-for="(item,index) in trends" :key="'ml'+index" @click="go(item.id)">
				<img :src="item.pic" alt="">
				<p class="title">{{ item.competitionName }}</p>
				<p class="time">赛事时间：{{ item.startDated | vuedateAll }} ~ {{ item.endDated | vuedateAll }}</p>
				<p class="time">奖励：{{ item.rewards }}</p>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	//vue文件
	import detail from "../components/detail.vue"
	import particles from "../components/particles.vue"
	import {
		competitionDetail
	} from "@/api/api.js"
	export default {
		components: {
			detail,
			particles
		},
		props:['isnavS'],
		data() {
			return{
				componentKey: 0,
				trends: null,
				input: '',
				dialogVisible: false,
				radio: 3,
				input1: '范特西',
				checked: false,
				user: [],
				subVisible: false,
				fileList:[],
				subVisible2: false,
				pages: {
					page: 1,
					limit: 20
				},
				trendId: null,
				offsetTop: 0.,
				actindex: 0,
				tData: [],
				is: true,
				isFixedNav: false,
				width:{
					width: 0,
					left: 0
				},
				detailindex: 0,
				isGaolliang: true
			}
		},
		mounted() {
			this.competitionDetail()
			this.offsetTop = document.querySelector('#navlist').offsetTop;
			
			// Particles.init({
			//   selector: ".background"
			// });
		},
		destroyed() {
			let that = this;
			window.removeEventListener('scroll', that.handleScroll, true)
		},
		methods: {
			xiaohui(ele, index) {
			  let that = this;
			  window.removeEventListener('scroll', that.handleScroll, true);
			    let classname = "." + ele;
			    that.actindex = index;
			    that.$scrollTo(classname, {
			      //duration: 1000,
			      offset: -180,
						onStart() {
							that.isGaolliang = false
						},
						onDone() {
							that.isGaolliang = true
						},
			    });
				//setTimeout(function() {
			   window.addEventListener('scroll', that.handleScroll, true);
			  //}, 300);
			},
			
			// 首页显示列表
			competitionDetail() {
				competitionDetail({
					isTop: 1
				}).then(res => {
					console.log('首页显示列表',res.data)
					this.trends = res.data
					this.width.width = document.documentElement.clientWidth/res.data.length+"px"
					this.trendId = res.data[0].id
					this.tData = res.data[0]
					let that = this
					this.$nextTick(() => {
						window.addEventListener('scroll', that.handleScroll, true)
					})
				})
			},
			// 监听滚动
			handleScroll() {
				let that = this
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if(scrollTop <= this.offsetTop) {
					this.isFixedNav = false
				} else {
					this.isFixedNav = true
				}
				if(this.isGaolliang){
					// 滚动 高亮
					this.$nextTick(() => {
						let n = 0
						for(var i in that.$refs.detail.$refs) {
							let top = that.$refs.detail.$refs[i][0].getBoundingClientRect().top
							if(top<225) {
								that.actindex = n
								n++
							}
						}	
					})
				}
			},
			changeNav(id,index) {
				let that = this
				this.componentKey++
				this.trendId = id
				this.actindex = 0
				let width = this.width.width.indexOf('px')>-1 ? this.width.width.substring(0,this.width.width.length-2) : this.width.width
				this.width.left = width*1*(index*1) + 'px'
				this.tData = this.trends[index]
				if(this.isnavS){
					this.$scrollTo('.top',{
						// duration: 500,
						offset: -160,
						onStart() {
							that.isGaolliang = false
						},
						onDone() {
							that.isGaolliang = true
						},
					});
				}else {
					this.$scrollTo('.top',{
						// duration: 500,
						offset: -195,
						onStart() {
							that.isGaolliang = false
						},
						onDone() {
							that.isGaolliang = true
						},
					});
				}
				
			},
			add() {
				this.user.push({
					name: '',
					tel: ''
				})
			},
			del() {
				this.user.splice(0,1)
			},
			handleChange(file, fileList) {
				this.fileList = fileList.slice(-3);
			},
			// 跳转赛事详情
			go(id) {
				this.$router.push(`/competitions/${id}`)
			}
		}
	}
</script>

<style lang="scss" scoped>

	.banner {
		padding: 90px 0 140px;

		.title {
			color: rgba(238, 238, 238, 1);
			font-size: 60px;
			text-align: left;
			line-height: 1.5;
		}

		.subtit {
			color: rgba(255, 255, 255, 1);
			font-size: 30px;
			text-align: left;
			line-height: 1.5;
			width: 70%;
		}

		.btn {
			display: flex;
			margin-top: 60px;

			span {
				padding: 20px 30px;
				border-radius: 5px;
				font-size: 20px;
			}

			span:nth-child(1) {
				background: #9dc3f7;
				color: #fff;
			}

			span:nth-child(2) {
				background: none;
				color: #9dc3f7;
			}
		}
	}

	.content {
		.navlist {
			border-top: 1px solid rgba(38, 38, 38, 1);
			border-bottom: 1px solid rgba(38, 38, 38, 1);
			height: 60px;
			color: #fff;
			font-size: 20px;

			.nav {
				display: flex;
				height: 100%;
				cursor: pointer;

				p {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		.navcon {
			display: flex;
			margin-top: 30px;

			.left {
				width: 60px;
				min-width: 60px;
				margin-right: 40px;

				p {
					color: #A3A3A3;
					font-size: 14px;
					height: 32px;
					line-height: 32px;
				}

				p.act {
					color: #fff;

					span {
						display: inline-block;
						border-bottom: 1px solid #9dc3f7;
					}
				}
			}

			.center {
				.top {
					display: flex;
					justify-content: space-between;
					img {
						width: 180px;
						height: 108px;
						border-radius: 10px;
					}
				}
				.title {
					font-size: 30px;
					line-height: 1.2;
				}

				.time {
					margin-top: 8px;
				}

				.price,
				.time {
					color: #A3A3A3;
					line-height: 2;
					font-size: 14px;
				}

				.step {
					.step_title {
						font-size: 18px;
						font-weight:600;
						color: #EEEEEE;
						line-height: 2;
						margin-top: 35px;
					}

					.step_con {
						font-size: 14px;
						color: #A3A3A3;
						line-height: 1.8;
					}
				}
			}
		
			.right {
				width: 150px;
				min-width: 150px;
				margin-left: 40px;
				.finish {
					font-size: 18px;
					color: #1097f0;
					display: flex;
					align-items: center;
					i {
						color: #1097f0;
						margin-right: 5px;
						font-size: 20px;
					}
				}
				.title {
					padding: 10px 15px;
					border-radius: 5px;
					font-size: 18px;
					display: inline-block;
					background: #9dc3f7;
					color: #fff;
				}
				.subtit {
					font-size: 16px;
					color: #fff;
					padding: 10px 0 20px;
				}
				.timeline {
					color: #8B8B8B;
					font-size: 16px;
					.date {
						margin-bottom: 10px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						span {
							font-size: 22px;
							color: #0067DD;
							margin-left: 10px;
						}
					}
					.line {
						border-left: 1px solid #0067DD;
						position: relative;
						height: 240px;
						margin-left: 20px;
						padding-left: 30px;
						.sub {
							color: #fff;
							line-height: 2;
						}
						span {
							position: absolute;
							z-index: 2;
							top: -0px;
							left: -11px;
							font-size: 22px;
							display: inline-block;
							background-color: #151515;
							border-radius: 50%;
							color: #0067DD;
						}
						div {
							position: absolute;
							width: 6px;
							background: #0067DD;
							height: 0;
							left: -4px;
							z-index: 1;
							border-radius: 10px;
						}
					}
					.act {
						span {
							color: #0067DD;
							font-size: 20px;
						}
						div {
							position: absolute;
							width: 6px;
							background: #0067DD;
							height: 100px;
							left: -4px;
							z-index: 1;
							border-radius: 10px;
						}
					}
				}
			}
			
		}
	}
	
	.ml {
		margin-left: auto;
	}
	
	::v-deep {
		.el-dialog {
			background: #222222;
			border: 1px solid #404040;
			border-radius: 10px;
			.el-dialog__header {
				height: 60px;
				padding: 0 25px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #404040;
				.el-dialog__title {
					color: #fff;
				}
				.el-dialog__headerbtn {
					color: #fff;
				}
			}
			.el-dialog__body {
				padding: 16px 25px 50px;
				border-bottom: 1px solid #404040;
				.el-upload {
					width: 100%;
				}
				.text {
					font-size: 14px;
					color: #A3A3A3;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 10px 0 0;
					span {
						text-decoration: underline;
						color: #1097f0;
					}
				}
				.mianze {
					background: #404040;
					padding: 20px;
					color: #eee;
					font-size: 14px;
					line-height: 2;
					border-radius: 10px;
					text-align: left;
				}
				.upd {
					background: #404040;
					border: 1px solid #BBBBBB;
					border-radius: 10px;
					width: 100%;
					padding:40px 0 15px;
					color: #eee;
					font-size: 16px;
					p {
						display: flex;
						align-items: center;
						justify-content: center;
						i {
							color: #9dc3f7;
							font-size: 28px;
							margin-right: 8px;
						}
					}
					.el-upload__tip {
						font-size: 14px;
						line-height: 2;
						color: #A3A3A3;
					}
				}
				.upd1 {
					background: #404040;
					border: 1px solid #BBBBBB;
					border-radius: 10px;
					width: 100%;
					height: 60px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #eee;
					font-size: 16px;
					padding: 0 20px;
					img {
						margin-right: 10px;
					}
					.el-icon-close {
						margin-left: auto;
						font-size: 20px;
					}
				}
				.newtext {
					padding-left: 140px;
				}
				.adduser {
					margin-top: 10px;
					padding: 10px 20px;
					border-radius: 10px;
					background: #404040;
					display: flex;
					align-items: center;
					div {
						flex: 1
					}
					.inputdiv {
						display: flex;
						align-items: center;
						margin: 10px 0;
						font-size: 16px;
						span {
							display: inline-block;
							width: 100px;
							color: #eee;
						}
					}
					.el-icon-delete-solid {
						font-size: 20px;
						margin-left: 30px;
						color: #eee;
					}
				}
				.dia {
					.tit {
						font-size: 30px;
						color: #eee;
					}
					.input {
						line-height: 2;
						color: #fff;
						margin-top: 20px;
						margin-bottom: 8px;
						font-size: 16px;
					}
					.el-textarea__inner,
					.el-input__inner {
						color: #eee;
						background: none;
						border: 1px solid #BBBBBB;
					}
					.bn {
						background: none;
						color: #1097f0;
						border: 1px solid #1097f0;
						margin-top: 10px;
					}
				}
			}
			.el-dialog__footer {
				padding: 0 25px;
				height: 90px;
				display: flex;
				align-items: center;
				.dialog-footer {
					display: flex;
					width: 100%;
					align-items: center;
					justify-content: space-between;
				}
				.el-checkbox__label {
					color: #fff;
				}
				.can {
					background: none;
					color: #fff;
					border:none;
				}
				.sub {
					background: #9dc3f7;
					color: #fff;
					border:none;
				}
			}
		}
	}
	.flex {
		display: flex;
		width: 100%;
		align-items: center;
		margin-top: 20px;
		div.input {
			width: 140px;
			min-width: 140px;
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			line-height: 100%;
			.el-input__inner {
				flex: 1;
				margin-bottom: 0;
			}
		}
	}
	
	
	@media screen and (max-width: 750px) {
		.banner {
			padding: 2rem 0;
			.title {
				font-size: 2rem;
			}
			.subtit {
				font-size: 1rem;
				width: 100%;
			}
			.btn {
				margin-top: 2rem;
				justify-content: space-between;
				span {
					font-size: 1rem;
					padding: 0;
					width: 49%;
					height: 3rem;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
		.trends {
			.title {
				font-size: 1.2rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				line-height: 3;
				span {
					font-size: 1rem;
				}
			}
			.list {
				display: flex;
				flex-direction: column;
				border: 1px solid #404040;
				padding: 10px;
				border-radius: 10px;
				margin-bottom: 20px;
				img {
					width: 100%;
					border-radius: 10px;
				}
				.title {
					font-size: 1.8rem;
					line-height: 2;
					padding-top: 5px;
				}
				.time {
					color: #a3a3a3;
					font-size: 1rem;
					line-height: 1.5;
				}
			}
		}
		
	}
</style>