var utils = {
	copyText(value, that) {
	  const aux = document.createElement('input')
	  aux.setAttribute('value', value)
	  document.body.appendChild(aux)
	  aux.select()
	  document.execCommand('copy')
	  document.body.removeChild(aux)
	  that.$message.success('复制成功')
	},
	
	// 判断日期大小
	compareDates(date1) {
	  return new Date() - new Date(date1);  // -86400000 (负数表示date1小于date2)
	},
	
	getDiffDay(date_1, date_2) {
		// 计算两个日期之间的差值
		let totalDays, diffDate
		let myDate_1 = Date.parse(date_1)
		let myDate_2 = Date.parse(date_2)
		// 将两个日期都转换为毫秒格式，然后做差
		diffDate = Math.abs(myDate_1 - myDate_2) // 取相差毫秒数的绝对值

		totalDays = Math.floor(diffDate / (1000 * 3600 * 24)) // 向下取整
		
		
		return totalDays // 相差的天数
	},
	
	// 明天日期 || 昨天
	tomorrow(dates, days) {
		const today = new Date(dates);
		const tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000)*days);
	  let year = tomorrow.getFullYear();
	  let month = tomorrow.getMonth() + 1;
	  let day = tomorrow.getDate();
	  if (month < 10) {
	    month = '0' + month;
	  }
	  if (day < 10) {
	    day = '0' + day;
	  }
	  return year + '-' + month + '-' + day;
	},

	getYMD() {
		let myDate = new Date()
		let myYear = myDate.getFullYear();
		let myMonth = myDate.getMonth() + 1;
		let myToday = myDate.getDate();
		myMonth = myMonth > 9 ? myMonth : '0' + myMonth
		myToday = myToday > 9 ? myToday : '0' + myToday
		let nowDate = myYear +'.'+ myMonth + '.' + myToday

		return nowDate
	},

	process(date_1, date_2) {
		var d0 = new Date().getFullYear().toString() + this.getYMD().replace(/\-/g, "");

		var d1 = new Date().getFullYear().toString() + date_1.replace(/\-/g, "");

		if (d0 * 1 < d1 * 1) {
			return -1;
		} else {
			return this.getDiffDay(this.getYMD(), date_1)
		}
	},

	// 间隔天数
	gap(time1, time2) {
		var d0 = new Date().getFullYear().toString() + this.getYMD().replace(/\-/g, "");

		var d1 = new Date().getFullYear().toString() + date_1.replace(/\-/g, "");

		if (d0 * 1 < d1 * 1) {
			return -1;
		} else {
			return this.getDiffDay(this.getYMD(), date_1)
		}
	},

	// 发布时间间隔
	getTimer(stringTime) {
		var minute = 1000 * 60;
		var hour = minute * 60;
		var day = hour * 24;
		var week = day * 7;
		var month = day * 30;

		var time1 = new Date().getTime(); //当前的时间戳
		var time2 = new Date(stringTime); //指定时间的时间戳
		var time = time1 - time2;

		var result = null;
		if (time < 0) {
			// alert("设置的时间不能早于当前时间！");
		} else if (time / month >= 1) {
			result = parseInt(time / month) + "月前";
		} else if (time / week >= 1) {
			result = parseInt(time / week) + "周前";
		} else if (time / day >= 1) {
			result = parseInt(time / day) + "天前";
		} else if (time / hour >= 1) {
			result = parseInt(time / hour) + "小时前";
		} else if (time / minute >= 1) {
			result = parseInt(time / minute) + "分钟前";
		} else {
			result = "刚刚发布";
		}
		return result;
	},

	getTime(time1, time2) {


		let dateBegin = new Date(time1); //将-转化为/，使用new Date

		let dateEnd = new Date(time2); //将-转化为/，使用new Date

		let dateNow = new Date(); //获取当前时间

		let beginDiff = dateNow.getTime() - dateBegin.getTime(); //时间差的毫秒数


		let beginDayDiff = Math.floor(beginDiff / (24 * 3600 * 1000)); //计算出相差天数

		let endDiff = dateEnd.getTime() - dateNow.getTime(); //时间差的毫秒数

		let endDayDiff = Math.floor(endDiff / (24 * 3600 * 1000)); //计算出相差天数

		if (endDayDiff < 0) { //已过期
			return false;
		}

		if (beginDayDiff < 0) { //还没到时间
			return false;
		}

		return true;

	},
	
	// 循环时间段中日期
	dealDate(start,end){
	    let dateList = [];
	    var startTime = new Date(start);;
	    var endTime = new Date(end);;
	
	    while ((endTime.getTime() - startTime.getTime()) >= 0) {
	        var year = startTime.getFullYear();
	        var month = startTime.getMonth() + 1 < 10 ? '0' + (startTime.getMonth() + 1) : startTime.getMonth() + 1;
	        var day = startTime.getDate().toString().length == 1 ? "0" + startTime.getDate() : startTime.getDate();
	        // dateList.push(year + "/" + month + "/" + day);
	        dateList.push(year + "."+ month + "." + day);
	        startTime.setDate(startTime.getDate() + 1);
	    }
	    return dateList;
	},
	
	// 截取月日	
	submad(value){
		let appointDate = /\d{4}-\d{1,2}-\d{1,2}/g.exec(value)[0];
		appointDate = appointDate.split('-')[0]+"."+appointDate.split('-')[1]+"."+appointDate.split('-')[2]
		return appointDate
	},
	
	// 相邻时间点重合-取前者，例如09-04,09-05，取09-04
	noRepeat1(arr) {
		let newarr = []
			for (var i = 0; i < arr.length; i++) {
					 // i = 8  依次和后面的 7,6,5,4,3,2,1比较
					 for (var j = i + 1; j < arr.length; j++) {
							 // 如果这一轮中的某个值 比当前要比较的值小 则交换位置
							 let time1 = new Date(arr[i].replace(/\./g, "/")).getTime()
							 let time2 = new Date(arr[j].replace(/\./g, "/")).getTime()
							 if ((time2 - time1)/10000==8640 || (time2 - time1)/10000==-8640 || (time2 - time1)/10000==0) {
								 newarr.push(arr[j])
							 }
		 
					 }
		 
			 }
			 return newarr
	},
	
	continueDays(arr_days) {
	  // 先排序，而后转时间戳
	  let days = arr_days.sort().map((d, i) => {
	    let dt = new Date(d)
	    dt.setDate(dt.getDate() + 4 - i) // 处理为相同日期
	
	    // 抹去 时 分 秒 毫秒
	    dt.setHours(0)
	    dt.setMinutes(0)
	    dt.setSeconds(0)
	    dt.setMilliseconds(0)
	
	    return +dt
	  })
	
	  let ret = true
	
	  days.forEach(d => {
	    if (days[0] !== d) {
	      ret = false
	    }
	  })
	
	  return ret
	}


}



export default utils