import Vue from 'vue'
import Vuex from 'vuex'
import { MessageBox, Message } from "element-ui";
import { getUserInfo } from '@/api/api.js'

import router from "@/router"
import Cookies from 'js-cookie';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		token: Cookies.get('authorization') || '',
		userId: Cookies.get('userId') || '',
		userName: Cookies.get('userName') || '',
		phone: Cookies.get('phone') || '',
		email: Cookies.get('email') || '',
		isFixedNav: Cookies.get('isFixedNav') || true
  },
  getters: {
  },
  mutations: {
		SET_TOKEN(state, val) {
			state.token = val
			Cookies.set('authorization', val)
		},
		SET_USERID(state, val) {
			state.userId = val
			Cookies.set('userId', val)
		},
		SET_USERNAME(state, val) {
			state.userName = val
			Cookies.set('userName', val)
		},
		SET_PHONE(state, val) {
			state.phone = val
			Cookies.set('phone', val)
		},
		SET_EMAIL(state, val) {
			state.email = val
			Cookies.set('email', val)
		},
		SET_FIXNAV(state,val) {
			state.isFixedNav = val
			Cookies.set('isFixedNav', val)
		},
		LOGOUT(state, val) {
			state.token = val
			Cookies.remove('authorization')
			state.userId = val
			Cookies.remove('userId')
			state.userName = val
			Cookies.remove('userName')
			state.phone = val
			Cookies.remove('phone')
			state.email = val
			Cookies.remove('email')
			router.push('/')
		}
  },
  actions: {
		USERINFO(context, data) {
			console.log('params',data)
			getUserInfo({
				userId: data
			}).then(res => {
				let data = res.data
				context.commit('SET_USERID', data.userId)
				context.commit('SET_USERNAME', data.userName)
				context.commit('SET_PHONE', data.phone)
				context.commit('SET_EMAIL', data.email)
			})
		},
  },
  modules: {
  }
})
