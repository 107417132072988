
<template>
  <div style="min-height:calc(100vh - 90px); height:100px;">
    <div id="particles-js"></div>
  </div>
</template>

<script>
  import particlesJs from "@/components/particles.js";
  import particlesConfig from "@/utils/particles.json";
  export default {
    data() {
      return {};
    },
    mounted() {
    	particlesJS("particles-js", particlesConfig);
    },
  }
</script>
<style>
	#particles-js{
	      position: absolute;
	      width: 100%;
	      height: 100%;
	      background-color: #151515;
	      background-repeat: no-repeat;
	      background-size: cover;
	      background-position: 50% 50%;
	}
</style>
